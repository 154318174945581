import React, { Component } from "react";
import Payments from "./payments";
import ChangeOrders from "./changeOrders";
import { Tabs } from "antd";
import Documents from "./documents";
import Photos from "./photos";
import Chats from "./chats";
import Apiservice from "../constants/Apiservice";
import { withRouter } from 'react-router-dom';
export default class Tab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      payments: [],
      project: {},
    };
  }






  render() {
    const id_value = {
      client_id: this.props.id,
      pro_id: this.props.secondId
    }


    const items = [
      {
        key: "1",
        label: "Payments",
        children: <Payments id_value={id_value} payments={this.props.payments} />
      },
      {
        key: "2",
        label: "Change Orders",
        children: <ChangeOrders id_value={id_value} />,
      },
      {
        key: "3",
        label: "Documents",
        children: <Documents  id_value={id_value}/>
      },
      // {
      //   key: "4",
      //   label: "Photos",
      //   children: <Photos/>,
      // },
      // {
      //   key: "5",
      //   label: "Chats",
      //   children: <Chats id_value={id_value}/>,
      // },
    ];
    return (
      <>
        <Tabs className="customTabs" defaultActiveKey="1" items={items} onChange={this.onChange} />
      </>
    );
  }
}

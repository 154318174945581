import React, { Component } from "react";
import { setCookie, invoicePay, returnSearchObject, formatAmountWithCommas, fixedAmount } from "../constants/utils";
import { Button, Spinner } from "reactstrap";
import Apiservice from "../constants/Apiservice";
import { Custom_Button } from "./Buttons/customButton";

let selectType;

class PayMethod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            directPay: true,
            cardPay: false,
            total: 0,
            cardAmount: 0,
            bank_amount: 0,
            btnLoading: false,
            loading: true,
            commission: "",
            achCommision: 0,
            ach: {},
        };
    }

    componentDidMount = () => {
        this.getInvoiceCommission();
    };


    componentDidUpdate(prevProps, prevState) {
        const { amount } = this.props;

        if (amount !== prevProps?.amount) {
            this.getInvoiceCommission();
        }
    }








    getInvoiceCommission = async () => {
        let response = await Apiservice.getAPI(`auth/contractor/commission`);
        if (response.success) {
            let { amount } = this.props
            let totalAmt = amount;

            let { directPay } = this?.state;
            let commission = await response?.data?.commission?.card?.commission;

            let tot = invoicePay(Number(totalAmt), Number(commission));

            let cardamount = (Number(tot) - Number(totalAmt))?.toFixed(2);

            let ach = await response.data.commission.ach;


            this.setState({ ach: ach, achCommision: ach?.commission });

            if (Number(totalAmt) < Number(ach.amount)) {
                this.setState({ bank_amount: Number(ach?.commission) });
            }

            if (directPay) {
                this.setState(
                    {
                        total: Number(totalAmt),
                        cardAmount: Number(cardamount) ?? "-",
                        commission: Number(commission),
                    },
                    () => {
                        this.setState({ loading: false }, () => {
                            if (Number(this?.state?.bank_amount) < Number(this?.state?.cardAmount)) {
                                this.setState({
                                    directPay: true,
                                    cardPay: false,
                                    total: Number(totalAmt) + Number(this?.state?.bank_amount),
                                });
                            } else
                                this.setState({
                                    directPay: false,
                                    cardPay: true,
                                    total: Number(totalAmt) + Number(this?.state?.cardAmount),
                                });
                        });
                    }
                );


            }



        }
    };


    handlePaymentTypeChange = (e) => {
        let totalAmt = this.props.amount;
        let { commission, bank_amount } = this.state;
        if (e.target.value === "direct") {
            this.setState({
                directPay: true,
                cardPay: false,
                total: Number(totalAmt) + Number(bank_amount),
            });
        } else {
            let tot = invoicePay(Number(totalAmt), Number(commission));

            this.setState({ directPay: false, cardPay: true, total: tot });
        }
    };

    handlePayment = async () => {
        this.setState({ btnLoading: true });
        let invoiceId = this.props.invoice_id;
        let { cardPay } = this.state;
        let clientId = this.props.props.id_value.client_id
        //   let url = (invoiceId && invoiceId !== "") ? invoiceId : selectType ? `bulk?for=${selectType}` :'bulk'
        let data = {
            success_url: `${window.location.href}?pay_status=success`,
            cancel_url: `${window.location.href}?pay_status=failure`,
            type: cardPay ? "card" : "us_bank_account",
        };
        let response = await Apiservice.postAPI(`auth/contractor/client/pay/${invoiceId} `, data);
        if (response.success) {
            setCookie("invoice_id", response.data.transaction_id);
            window.open(response.data.link, "_self");
        }
    };



    closefun = () => {
        this.props.close()
        this.setState({
            directPay: true,
            cardPay: false,
            total: 0,
            cardAmount: 0,
            bank_amount: 0,
            btnLoading: false,
            loading: true,
            commission: "",
            achCommision: 0,
            ach: {},
        })
    }



    render() {
        let { amount } = this.props;
        let {
            directPay,
            cardPay,
            cardAmount,
            total,
            btnLoading,
            loading,
            achCommision,
            commission,
            bank_amount,
            ach,
        } = this.state;


        console.log(this.props.ismodal, "321213123")


        return false ? (
            <div className="spin-loading1">
                <Spinner type="border" color="#012A4D" />
            </div>
        ) : (
            <div>
                <div
                    style={{ padding: "20px", display: "flex", justifyContent: "space-between" }}
                    className="flex payMethodHeader f18"

                >
                    <div style={{ fontSize: '18px', color: '#212121', fontWeight: '600' }}>Payment Method</div>
                    <div

                        style={{
                            fontSize: '18px',
                            fontWeight: '600', color: "#fff",
                            background: '#E8E8E8', width: '25px',
                            height: "25px", alignItems: 'center',
                            display: 'flex', justifyContent: 'center',
                            cursor: 'pointer'
                        }}
                        onClick={() => this.closefun()}
                    >
                        X
                    </div>
                </div>
                <div style={{ padding: '20px' }}>
                    <div style={{ paddingTop: '20px', paddingBottom: "20px", fontSize: '16px', color: '#212121', fontWeight: '500' }}>
                        Please Select the payment method to proceed
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', fontWeight: '600', fontSize: '16px', padding: '0px 30px', background: '#FCFCFC', marginBottom: '30px' }}>
                        <p>Invoice amount</p>
                        <p>{"$" + formatAmountWithCommas(fixedAmount(amount))}</p>
                    </div>
                    <div style={{ fontWeight: '600', fontSize: '16px', padding: '0px 30px 30px 30px' }}>
                        Transaction Fee{" "}
                    </div>
                    <div className="padl30 padr30">
                        <div
                            style={{ display: "flex", justifyContent: 'space-between', alignItems: "start" }}
                            className={
                                directPay ? "paymentMethod mb-15" : "paymentMethod payDim mb-15  reduceopacity"
                            }
                        >
                            <div className="radio-container " style={{ display: "flex", alignItems: 'flex-start' }}>
                                <input
                                    type="radio"
                                    name="payMethod"
                                    id="directPayment"
                                    onChange={this.handlePaymentTypeChange}
                                    value="direct"
                                    style={{ marginRight: "10px", marginTop: "2px", marginTop: '5px' }}
                                    className={directPay ? "point" : "inactiveRadio point"}
                                    checked={directPay}
                                />
                                <div className="f14 fw-400 point" >
                                    <label htmlFor="directPayment" className="point flex-column">
                                        <div className="mb-5p">
                                            <span style={{ color: '#525F7F', fontSize: '16px', fontWeight: '700' }}>Bank Transfer</span>{" "}
                                            {this.state.bank_amount < this.state.cardAmount && (
                                                <span style={{ color: '#525F7F', fontSize: '16px', fontWeight: '700' }}>
                                                    {" (Recommended)"}
                                                </span>
                                            )}
                                        </div>

                                        {amount < ach.amount ? (
                                            <div style={{ color: '#525F7F', fontSize: '15px', fontWeight: '600', marginTop: '5px', marginBottom: '20px' }}>
                                                You will be charged ${achCommision} as transaction fee
                                            </div>
                                        ) : (
                                            <div style={{ color: '#525F7F', fontSize: '15px', fontWeight: '600', marginTop: '5px', marginBottom: '20px' }}>No transaction fee</div>
                                        )}
                                    </label>
                                </div>
                            </div>
                            <div style={{ color: '#525F7F', fontSize: '16px', fontWeight: '700', paddingRight: "30px" }}>

                                {"$" + formatAmountWithCommas(fixedAmount(bank_amount))}
                            </div>
                        </div>
                        <div
                            style={{ display: "flex", justifyContent: 'space-between', alignItems: "start" }}
                            className={
                                cardPay ? "paymentMethod mb-15" : "paymentMethod payDim mb-15 reduceopacity"
                            }
                        >
                            <div className="radio-container  " style={{ display: "flex", alignItems: 'flex-start' }}>
                                <input
                                    type="radio"
                                    name="PayMethod"
                                    id="cardPayment"
                                    value="card"
                                    onChange={this.handlePaymentTypeChange}

                                    style={{ marginRight: "10px", marginTop: "2px", marginTop: '5px', }}
                                    className={cardPay ? "point" : "inactiveRadio"}
                                    checked={cardPay}
                                />

                                <div className="f14 fw-400 point">
                                    <label htmlFor="cardPayment" className="point flex-column">
                                        <div className="mb-5p">
                                            <span style={{ color: '#525F7F', fontSize: '16px', fontWeight: '700' }}>Card Payment</span>
                                            {this.state.bank_amount > this.state.cardAmount && (
                                                <span className="f11 fw-400 point">
                                                    {" (Recommended)"}
                                                </span>
                                            )}
                                        </div>
                                        <div style={{ color: '#525F7F', fontSize: '15px', fontWeight: '600', marginTop: '5px', marginBottom: '20px' }}>
                                            You will be charged {commission}% of invoice + 30 cent
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <div style={{ color: '#525F7F', fontSize: '16px', fontWeight: '700', paddingRight: "30px" }}>
                                {"$" + fixedAmount(parseFloat((amount / 100) * commission) + 0.30)}
                            </div>
                        </div>
                    </div>
                    <div style={{
                        fontWeight: '700px', fontSize: '16px',
                        color: '#525F7F', padding: '0px 30px 30px 30px', display: 'flex',
                        justifyContent: 'flex-end', alignItems: 'center'
                    }}>
                        <span style={{ fontSize: "18px", fontWeight: '700', marginRight: '20px' }}>Total</span>{" "}
                        <div style={{ fontSize: "16px", fontWeight: '600' }}>
                            {"$" + formatAmountWithCommas(fixedAmount(total))}
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "30px" }}>
                        <Custom_Button
                            isPrimary
                            isLoading={btnLoading}
                            btnLabel={'proceed_to_payment'}
                            click={() => this.handlePayment()}
                        />

                    </div>
                </div>
            </div>
        );
    }
}

export default PayMethod;

import React, { Component } from "react";
import Document from "../assets/Company/documents.svg";
import Download from "../assets/Company/download.svg";
import Apiservice from "../constants/Apiservice";
import { capitalizeFirstLetter, dateformat, getCloudinaryImage, overflowName } from "../constants/utils";
import showmore from "../assets/Company/icons/showMore.svg"
import { Empty } from "antd";
import Env from "../constants/Env";
import pdf from "../assets/Company/icons/pdf _icon.svg"
import file_icon from "../assets/Company/icons/file_icon.svg"
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";


export default class Documents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      downloadLink: "",
      downloadName: "",
      itemStates: (this?.state?.documents || [])?.map(() => false)
    };
  }


  componentDidMount = () => {
    this.documentGet()
  }

  componentDidUpdate(prevProps, prevState) {
    const { id_value } = this.props;
    const projectId = id_value?.pro_id;
    if (projectId !== prevProps.id_value?.pro_id) {
      this.documentGet();
    }
  }

  documentGet = async () => {
    const { id_value } = this.props
    const response = await Apiservice?.getAPI(`auth/contractor/document/${id_value?.client_id}/${id_value?.pro_id}`)

    if (response.success) {
      this.setState({ documents: response?.data?.documents })
    }

  }


  download = async (downloadLink, downloadname, index) => {
    const id = this.props.id_value;
    this.toggleItem(index)

    try {
      const response = await fetch(downloadLink);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = downloadname;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this.setState({ itemStates: (this?.state?.documents || [])?.map(() => false) })

    } catch (error) {
      console.error('Error downloading file:', error);
      this.setState({ itemStates: (this?.state?.documents || [])?.map(() => false) })
    }
  }


  toggleItem = (index) => {
    this.setState(prevState => {
      const newStates = Array.isArray(prevState.itemStates) ? [...prevState.itemStates] : (this?.state?.documents || [])?.map(() => false);
      newStates[index] = !newStates[index];
      return { itemStates: newStates };
    });
  };



  render() {
    const { documents } = this.state



    return (
      <div className={`${documents.length > 0 ? "documents mt_20" : ""}`}>
        {documents?.length > 0 ?

          documents?.map((obj, index) => {

            return (

              <div key={index} className="card" style={{ cursor: "pointer" }} >

                <a target="_blank" href={getCloudinaryImage(500, 500, obj.document, "c_fill")} style={{ display: "flex", justifyContent: "start", alignItems: "center", width: "100%" }}>
                  {
                    ["png", "jpg", "jpeg", "gif", "svg"].includes(obj.document.format) ? <a
                      target="_blank" href={getCloudinaryImage(500, 500, obj.document, "c_fill")}
                    >
                      <img
                        src={getCloudinaryImage(
                          100,
                          100,
                          obj.document,
                          "c_fill"
                        )}
                        style={{ width: 56, height: 56, borderRadius: "6px" }}
                        alt="profile image"
                        className="avatar avatar-sm rounded-circle point"
                      />
                    </a>
                      : <img src={file_icon} style={{ width: 56, height: 56 }} />
                  }

                  <div style={{ paddingLeft: "12px" }}>
                    <div className="doc-title">
                      {overflowName(obj?.name)}
                    </div>
                    <div className="doc-subtitle">
                      {"Added on "+dateformat(obj.createdAt)}
                    </div>
                  </div>
                </a>

                <div>

                  {
                    this?.state?.itemStates[index] ? <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 20,
                            fontWeight: 700,
                            color: "#002A4D",
                            alignSelf: "center",
                          }}
                          spin
                        />
                      }
                    /> : <img
                      onClick={() => this?.download(getCloudinaryImage(500, 500, obj.document, "c_fill"), obj?.name, index)}
                      style={{ width: "13px", height: "25px", cursor: "pointer" }} src={Download} />

                  }

                </div>

              </div>


            )
          })

          : <div style={{ width: "100%", height: "270px", justifyContent: "center", alignItems: "center", display: "flex" }}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></div>
        }
      </div>
    );
  }
}

import React, { Component } from "react";
import
{ Modal,Spin }
from
"antd"
;
import { showError,showSuccess } from "../../constants/utils";
import { remove_underscore_capitalize } from "../../constants/utils";
import { Custom_Button } from "../Buttons/customButton";
import TextArea from "antd/es/input/TextArea";
import Apiservice from "../../constants/Apiservice";
// import 'bootstrap/dist/css/bootstrap.min.css';

class EstimateAccepRejecttModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      btnLoad: false,
      acceptLoader: false,
      reason: '',
    };
  }
  

 
  triggetFunction = () => {
   
    if (this.props.subject === 'accept'||this.props.subject==='reject'&&this.state.reason) {
      this.setState({ acceptLoader: true })
      this.props.action(this.state.reason)
     }else{
     showError(this.props.props,"Please enter the reject reason")
    }

  };















  render() {
    let { btnLoad, acceptLoader, reason, showError } = this.state;




    return (
      <div>
        <Modal
          open={this.props.isOpen}
          footer={null}
          closable={false}
          centered={true}
          style={{ minWidth: "25rem", padding: "2rem" }}
        >
          <div style={{ padding: "24px" }}>
            <div className="conCanMod">
              {this.props.subject[0]?.toUpperCase() +
                this.props.subject?.slice(1)}
            </div>
            <div style={{ textAlign: "center", wordBreak: "break-word" }}>
              Are you sure you want to {this.props.subject} {this.props.estimate}
            </div>

            {
              this.props.subject === 'reject' && <div>
                {/* <p>Reject reason</p> */}
                <TextArea autoSize={{ minRows: 4, maxRows: 4 }}  maxLength={150}  placeholder="Reject reason" style={{ marginTop: "25px",wordBreak:'break-all' }} onChange={(e) => this.setState({ reason: e.target.value })} />
              </div>
            }



            <div
              className="flexEnd"
              style={{ marginTop: '16px', justifyContent: "center", gap: "5%" }}
            >

              <div
                className="point f14 button-secondary"
                style={{ fontWeight: "500" }}
                onClick={()=>this.props.props.toggleModal()}
                disabled={this.state.isApiCallPending}

              >
                {btnLoad ? (
                  <div className="spin-loading-save w100 h100">
                    <Spin
                      style={{
                        height: "20px",
                        width: "20px",

                      }}
                      type="border"
                      color="#0A8080"
                    />
                  </div>
                ) : (
                  <div >Cancel</div>
                )}
              </div>

              <Custom_Button
                isLoading={acceptLoader}
                isPrimary
                btnLabel={this.props.subject === 'accept' ? "Accept" : "Reject"}

                click={() => this.triggetFunction()}
              />

            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

export default EstimateAccepRejecttModal;

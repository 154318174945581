import React, { useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { remove_underscore_capitalize } from "../../constants/utils";

export function Custom_Button({
  isPrimary = false,
  btnLabel,
  className,
  click,
  isLoading = false,
  style,
  hardText = false,
}) {
  useEffect(() => { }, [isLoading]);
  return (
    <button
      onClick={click}
      style={{ minWidth: "100px", ...style, width: isLoading && "100px" }}
      disabled={isLoading}
      className={`${className} ${isPrimary ? "custom_pri_btn" : "custom_sec_btn"
        }`}
    >
      {
        isLoading ? (
          <Spin
            style={{ color: "white" }}
            indicator={
              < LoadingOutlined
                className="custom_antd_spinner"
                style={{
                  fontSize: 16,
                }}
              />
            }
          />
        ) : (
          <div>
            {hardText ? btnLabel : remove_underscore_capitalize(btnLabel)}
          </div>
        )}
    </button >
  );
}
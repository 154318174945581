const stageConfig = {
     BASE_URL:"http://3.16.194.5:8000/api/v1/"
    //  BASE_URL:"http://192.168.0.185:1403/api/"


  };
  // http://192.168.0.187:1403
  const prodConfig = {
    BASE_URL: "https://api.parknstays.com/api/v1/",
  };
  
  export default "production" === process.env.REACT_APP_ENV
    ? prodConfig
    : stageConfig;
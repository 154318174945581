import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import routes from "../routes";
import Login from "../components/login";
export default class PublicRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const getRoutes = (routes) => {
      return routes.map((obj, key) => (
        <Route path={obj.path} component={obj.component}></Route>
      ));
    };
    return (
      <div>
        <Routes>
          {/* {getRoutes(routes)} */}
          <Route path="/login" exact Component={Login} />
          <Route path="*" element={<Navigate to="/login" />}/>
        </Routes>
      </div>
    );
  }
}

import React, { useEffect } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
function Breadcrumbs(props) {
  const navigate = useNavigate();
  const breadCrumbs = props.data;
  const breadCrumbsLength = breadCrumbs.length - 1
  function back() {
    navigate(-1);
  }
  return (
    <div className="breadCrumbsContainer">
      {breadCrumbs.map((obj, i) => (
        <div className="breadCrumbs">
          <div className={`name ${breadCrumbsLength === i ? "active" : "nonactive"}`} onClick={back}>{obj.name}</div>
          {!(breadCrumbsLength === i) && (
            <div  className="separator">/</div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Breadcrumbs;

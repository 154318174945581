import cookie from "react-cookies";
import moment from "moment";
import { message } from "antd";
import Apiservice from "./Apiservice";

export function getAuthToken() {
  return cookie.load("client");
}

export function isLoggedIn() {
  return getAuthToken() ? true : false;
}

export function removeCookie(key) {
  cookie.remove(key);
}

export function redirectLoginPage() {
  removeCookie("client");
  window.location.reload();
}

export const showMomentFormat = (date) => {
  return moment(date).format("MMM D, YYYY");
};

export const capitalize = (str) => {
  return [...str][0].toUpperCase() + [...str].slice(1).join("");
};

export const showMessage = (condition, content) => {
  message[condition](`${content}`);
};

export function isValidEmail(email) {
  const emailRegex = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  return emailRegex.test(email);
}


export const capitalizeFirstLetter = (str) => {
  if (!str) return ""
  return str.charAt(0).toUpperCase() + str.slice(1);
};


export const formatAmountWithCommas = (amount) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};


export const setCookie = (key, value) => {
  cookie.save(key, value, {
    path: "/",
    maxAge: 604800,
    secure: false,
  });
};

export const invoicePay = (amount, commission) => {
  let amt = amount + (amount * commission) / 100 + 0.3;

  return amt ? (parseFloat(amt))?.toFixed(2) : "-";
};

export const remove_underscore_capitalize = (string) => {
  if (!string) return "";
  if (string.includes("_") || string.includes(" ")) {
    return string
      .split(string.includes("_") ? "_" : " ")
      .map(
        (str) =>
          (typeof str === "string" ? str[0].toUpperCase() : str[0]) +
          str.slice(1)
      )
      .join(" ");
  } else if (string.length) return string[0].toUpperCase() + string.slice(1);
  else return string;
};


export const showSuccess = (self, message) => {
  showToast(self, "success", message);
};

export const showError = (self, message) => {
  showToast(self, "danger", message);
};

// Toast Handler
export const showToast = (self, type, message) => {
  let data = {
    toast: true,
    response: message,
    toastType: type,
  };
  self.setState(data, () => handleToastMessage(self, type));
};

export const handleToastMessage = (self, type) => {
  let { toast } = self.state;
  if (toast) {
    setTimeout(
      () => self.setState({ toast: false, response: "", toastType: "" }),
      type === "success" ? 3000 : 3000
    );
  }
};


export function getCookie(key) {
  return cookie.load(key);
}

export const showDatenTime = (date) => {
  return date ? moment(date).format("MMM DD,YYYY , H:mm a") : "-";
};

export const fixedAmount = (amount) => {
  const parsedAmount = parseFloat(amount);
  const separator = parsedAmount % 1 !== 0 ? parsedAmount.toFixed(2) : parsedAmount
  return formatAmountWithCommas(separator)
}

export const uploadCloudinary = async (file, path) => {
  const signResponse = await fetch(Apiservice.signInUrl, {
    method: "POST",
    body: JSON.stringify({ path }),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + getAuthToken(),
    },
  });
  const signData = await signResponse.json();

  const url =
    Apiservice.cloudinary_base_url + signData.data.cloudname + "/auto/upload";
  const formData = new FormData();
  formData.append("file", file);
  formData.append("api_key", signData.data.apikey);
  formData.append("timestamp", signData.data.timestamp);
  formData.append("signature", signData.data.signature);
  formData.append("eager", "c_pad,h_300,w_400|c_crop,h_200,w_260");
  formData.append("folder", signData.data.folder);
  let valley = formData.forEach((value, key) => (value, key));

  let response = await fetch(url, {
    method: "POST",
    body: formData,
  })
    .then((response) => {
      return response.text();
    })
    .then((data) => {
      return (response = JSON.parse(data));
    });
  return response;
};


export function getCloudinaryImage(width, height, image, c_type) {


  return (
    Apiservice.response_url +
    `/fl_lossy,w_${width},h_${height},${c_type}/${image.version}/${image.public_id}.${image.format}`
  );
}


export const dateformat = (date) => {
  return moment(date).format("MMM DD, YYYY")
}

function hasDuplicateQuantities(array) {
  const quantitiesSet = new Set();

  for (const item of array) {
    if (quantitiesSet.has(item.quantity)) {
      return true; // Quantity is repeated
    }
    quantitiesSet.add(item.quantity);
  }

  return false; // No duplicate quantities found
}
export const overflowName = (name) => {
  return name.length > 35 ? name.slice(0, 35) + "...." : name;
};
import React, { useEffect } from "react";
import Table from "../components/table";
import { Input, Breadcrumb } from "antd";
import {
  BrowserRouter as Router,
  useNavigate,
  Navigate,
} from "react-router-dom";
import ApiService from "../constants/Apiservice";
import Breadcrumbs from "../components/Breadcrumbs";

const requiredFields = ["name", "email", "phone_number", "address"];
function Profile() {
  const breadcrumbs = [
    {
      name: "Project Overview",
      path: "back",
    },
    {
      name: "profile",
      path: "",
    },
  ];
  const navigate = useNavigate();
  useEffect(() => {
    getClientDetails();
  }, []);

  const getClientDetails = async function () {
    let response = await ApiService.getAPI(`contractor/client/detail`);
  };

  function back() {
    navigate(-1);
  }

  return (
    <div className="p_30">
      {/* <div className="breadCrumbs">
        <div onClick={back}>Projects Overview</div>
        <div className="separator">/</div>
        <div>Profile</div>
      </div> */}
      <Breadcrumbs data={breadcrumbs} />
      <div className="profile_container mt_20">
        <div className="table">
          <div className="table_header">Basic Information</div>
          <div className="table_body">
            <div className="input name">
              <label className="profilelabel">Name*</label>
              <Input className="profileInput" placeholder="Name" />
            </div>
            <div className="input email">
              <label className="profilelabel">Email*</label>
              <Input className="profileInput" placeholder="Email" />
            </div>
            <div className="input phone">
              <label className="profilelabel">Phone*</label>
              <Input className="profileInput" placeholder="Phone" />
            </div>
            <div className="input address">
              <label className="profilelabel">Address*</label>
              <Input className="profileInput" placeholder="Address" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

import React, { Component, useEffect, useState } from "react";
import Logo from "../../assets/Company/logoPark.svg";
import Profile from "../../assets/Company/unsplash_ILip77SbmOE.svg";
import ApiService from "../../constants/Apiservice";
import Porfile from "../../assets/Company/dropdownProfile.svg"
import Logout from "../../assets/Company/dropdownLogout.svg"
import Dropdown_icon from "../../assets/Company/arrow - left.svg"
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
  useParams,
} from "react-router-dom";
import { Menu, Dropdown, Image, Select } from "antd";
import cookie from "react-cookies";
import { capitalize, capitalizeFirstLetter } from "../../constants/utils";
const { Option } = Select;
function ClientNavBar(props) {

  const { id, secondId } = props
  const [selectedProject, setSelectedProject] = useState();
  const [projects, setProjects] = useState([]);
  const [secondID, setsecondID] = useState("")
  const [Default, setDefault] = useState("")
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getClientDetails();
    }
  }, []);

  useEffect(() => {
    if (projects?.length !== 0) {
      projects?.length > 0 && setSelectedProject(projects[0].name)
      setsecondID(projects[0]?.project_id)
    }

  }, [projects]);

  useEffect(() => {
    if (secondID !== "" && Default === "") {
      navigate(`/client/${id}/${secondID}`)
    }
  }, [secondID])



  const getClientDetails = async function () {
    let projects;
    let response = await ApiService.getAPI(`auth/contractor/projects/${id}`);
    try {
      if (!response.success || (response.success && response.data.projects.length === 0)) {
        navigate("/notfound")
      } else {
        projects = response.data.projects;
        setProjects(projects);
      }
    } catch (err) {
      navigate("/notfound")

    }

  };



  const handleMenuClick = (e) => {
    if (e.item.props.action === "logout") {
      handleLogout();
    }
    if (e.item.props.action === "profile") {
      navigate("/client/profile");
    }
  };

  const handleLogout = () => {
    cookie.remove("client", { path: "/" });
    window.location.reload();
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" action="profile">
        <img src={Porfile} alt="profile image" />
        <span style={{ marginLeft: "10px" }}>Profile</span>
      </Menu.Item>
      <Menu.Item key="2" action="logout">
        <img src={Logout} alt="logout" />
        <span style={{ marginLeft: "10px" }}>Logout</span>
      </Menu.Item>
    </Menu>
  );


  const handleSelectChange = async (value, option) => {
    navigate(`/client/${id}/${value}`)
    setDefault(projects.find(option => option?.project_id === secondID))

  };


  return (
    <div className="navbar">
      <div className="header">
        <div className="header_head">
          <img src={Logo} height={28} alt="logo"></img>
        </div>

        <div className="header_side">
          {projects && projects?.length ? (
            <div className="select_project">
              <Select
                className="selector"
                defaultValue={Default ? Default?.name : projects[0]?.name}
                onChange={handleSelectChange}
              >

                {
                  projects.map((obj, i) => {
                    return (
                      <Option key={i} value={obj?.project_id} name={obj.name} >{obj.name}</Option>
                    )
                  })
                }

              </Select>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
export default ClientNavBar;

import React, { Component } from "react";
import Logo from "../assets/Company/logoPark.svg";
import { Input } from "antd";
import { showMessage,isValidEmail } from "../constants/utils";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { save } from 'react-cookies';

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      loading: false,
    };
  }

  logIn = (e) => {
    this.setState({ loading: true });
    const { email, password } = this.state;
    if (!email) {
      showMessage("error", "Please enter a email");
      this.setState({ loading: false });
    } 
    else if(!isValidEmail(email)) {
      showMessage("error", "Please enter a valid email");
      this.setState({ loading: false });
    }
    else if (!password) {
      showMessage("error", "Plesae enter a password");
      this.setState({ loading: false });
    } else {
      setTimeout(() => {
        this.setState({ loading: false });
      }, 1000);
      showMessage("success", "Login Successful");
      save('client', 'hari', { path: '/' });
      window.location.reload();
    }
  };
  render() {
    const { loading } = this.state;
    return (
      <div className="login_parent">
        <div className="login_child">
          <div className="login_logo">
            <img src={Logo}></img>
          </div>
          <div
            style={{
              textAlign: "start",
              marginTop: "20px",
              marginBottom: "20px",
            }}
            className="login_template"
          >
            Log In
          </div>
          <div className="login_fields">
            <div className="field">
              <div className="label">Email *</div>
              <Input
                onChange={(e) => this.setState({ email: e.target.value })}
                placeholder="Enter your email"
              />
            </div>
            <div className="field">
              <div className="label">Password *</div>
              <Input.Password
                placeholder="Enter your password"
                onChange={(e) => this.setState({ password: e.target.value })}
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </div>
          </div>
          <div className="login_footer">
            <button className="btn_primary_full_length" onClick={this.logIn}>
              {loading ? (
                <LoadingOutlined
                  style={{
                    fontSize: 18,
                  }}
                  spin
                />
              ) : (
                "Log In"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

import { redirectLoginPage, getAuthToken } from "./utils";
import Constants from "./Env";

class APIService {
  cloudinary_base_url = "https://api.cloudinary.com/v1_1/";
  response_url = "https://res.cloudinary.com/parknstays/image/upload";
  
  constructor() {
    let token = getAuthToken();
    this.headersAuthorization = {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + token,
    };
  }

  triggerResponse = async (response) => {
    if (response.status === 401) redirectLoginPage();
    return await response.json();
  };

  createInstance(method, data) {
    return {
      method,
      headers: this.headersAuthorization,
      body: JSON.stringify(data),
    };
  }

  getAPI = async (url) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("GET")
    );
    return this.triggerResponse(response);
  };
  postAPI = async (url, requestBody) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("POST", requestBody)
    );
    return this.triggerResponse(response);
  };
  patchAPI = async (url, data) => {
    const response = await fetch(
      Constants.BASE_URL + url,
      this.createInstance("PATCH", data)
    );
    return this.triggerResponse(response);
  };
}

export default new APIService();

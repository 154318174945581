import React, { useEffect, useState } from "react";
import {
  capitalizeFirstLetter,
  formatAmountWithCommas,
  getCookie,
  showMomentFormat,
} from "../constants/utils";
import { Modal, Progress, Spin, Tooltip } from "antd";
import Tab from "./tabs";
import ClientNavBar from "../components/navbar/ClientNavBar";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Apiservice from "../constants/Apiservice";
import moment from "moment";
import error from "../assets/Company/icons/error.svg";
import success from "../assets/Company/Lotties/successLottie.json";
import processing from "../assets/Company/Lotties/processingLottie copy.json";
import Lottie from "react-lottie-player";
import { LoadingOutlined } from "@ant-design/icons";

function Index() {
  const { id, secondId } = useParams();
  const [TooltipHover, setTooltipHover] = useState(false);
  const [showPop, setShowpop] = useState(false);
  const [failureStatus, setFailureStatus] = useState(false);
  const [proccessingStatus, setProccessingStatus] = useState(false);
  const [popLoading, setPopLoading] = useState(true);
  const [statusDetail, setStatusDetail] = useState();
  const [paymentStatus, setPaymentstatus] = useState();
  const [openPaymentStatusModel, setOpenPaymentStatusModel] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [response, setResponse] = useState([]);
  const [payments, setPayments] = useState([]);
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pay_status = queryParams.get("pay_status");

  useEffect(() => {
    if (id && secondId) {
      fetchExpensedetails();
    }
  }, [secondId]);

  useEffect(() => {
    if (pay_status === "failure") {
      setFailureStatus(true);

      setTimeout(() => {
        queryParams.delete("pay_status");
        window.history.replaceState(
          {},
          "",
          `${window.location.pathname}${queryParams}`
        );
        setFailureStatus(false);
      }, 4000);
    }
    if (pay_status === "success") {
      setOpenPaymentStatusModel(true);

      setTimeout(() => {
        getData();
      }, 4000);
    }
  }, [pay_status]);
  const deleteparam = () => {
    queryParams.delete("pay_status");
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}${queryParams}`
    );
  };

  const fetchExpensedetails = async () => {
    let response = await Apiservice.getAPI(
      `auth/contractor/projects/${id}/${secondId}`
    );
    if (response.success) {
      setPayments(response.data.payments);
      setResponse(response.data.project);
      let data = response.data.project;

      let customsizedata = [
        {
          title: "Service pro",
          value: data?.contractor?.name,
        },
        {
          title: "Start Date",
          value: moment(data.start_date).format("MMM DD, YYYY"),
        },
        {
          title: "End Date",
          value: moment(data.end_date).format("MMM DD, YYYY"),
        },
        {
          title: "Status",
          value: capitalizeFirstLetter(data.status),
        },

        {
          title: "Progress",
          value: data.progress,
        },
      ];

      setClientData(customsizedata);
    } else {
      navigate("/notfound");
    }
  };

  async function getData() {
    let invoiceID = getCookie("invoice_id");
    let response = await Apiservice.getAPI(
      `auth/contractor/transaction/${id}/${invoiceID}`
    );

    if (response.success) {
      if (response.data.transaction.status === "paid") {
        setStatusDetail(response.data.transaction);
        setPaymentstatus("success");
        setPopLoading(false);
        setTimeout(() => {
          deleteparam();
          setOpenPaymentStatusModel(false);
        }, 4000);
      } else if (response.data.transaction.status === "processing") {
        setPaymentstatus("processing");
        setPopLoading(false);

        setTimeout(() => {
          deleteparam();
          setOpenPaymentStatusModel(false);
        }, 4000);
      } else {
        setPaymentstatus("failure");
        setPopLoading(false);
        setTimeout(() => {
          deleteparam();
          setOpenPaymentStatusModel(false);
        }, 4000);
      }
    }
  }

  return (
    <div>
      <ClientNavBar id={id} secondId={secondId} />
      {clientData.length > 0 ? (
        <div className="p_30">
          <div className="top_card">
            <div className="W_800 fs_24">{response.name}</div>
            <div className="pt_25 cards">
              {clientData?.length > 0 &&
                clientData?.map((obj, key) => {
                  return (
                    <div key={key} className="card">
                      <div className="indiv_card">
                        <div className="W_500 fs_15 title">{obj.title}</div>
                        <div className="cardValue W_700">
                          {obj.title === "Progress" ? (
                            <div style={{display:'flex',alignItems:"center"}}>
 {/* <Tooltip
                              placement={`${
                                obj?.value > 50 ? "topRight" : "top"
                              }`}
                              title={`${Math?.ceil(obj.value)}%`}
                              visible={TooltipHover}
                            > */}
                             
                              <Progress
                                className="custom-progress-bar"
                                style={{ borderRadius: "10px" }}
                                showInfo={false}
                                strokeWidth={10}
                                percent={obj.value ?? 0}
                                size={[200, 30]}
                                strokeColor={obj.value > 80 ?"green" :""  }
                                onMouseEnter={() => setTooltipHover(true)}
                                onMouseLeave={() => setTooltipHover(false)}
                              />
                            {/* </Tooltip> */}
                            <div style={{fontSize:"13px"}}>
                            {obj.value?Math?.ceil(obj.value)+"%":"0%"}
                            </div>
                            </div>
                           
                          ) : (
                            <>{obj.value}</>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div style={{ marginTop: "20px" }} className="tabs">
            <Modal
              open={failureStatus}
              footer={null}
              centered={true}
              closable={false}
              width={450}
            >
              <div
                className=""
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  paddingTop: "55px",
                  paddingBottom: "30px",
                }}
              >
                <img src={error} style={{ paddingBottom: "20px" }} />
                <div className="failure-status">Payment failed</div>
                <div className="text-center-css">
                  {" "}
                  Your payment was not successfully processed
                  <br />
                  Please try again after some time
                </div>
              </div>
            </Modal>
            <Modal
              open={openPaymentStatusModel}
              footer={null}
              centered={true}
              closable={false}
              width={450}
            >
              {popLoading ? (
                <div
                  style={{
                    width: "450px",
                    height: "450px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 45,
                          fontWeight: 900,
                          color: "#002A4D",
                          alignSelf: "center",
                        }}
                        spin
                      />
                    }
                  />
                  <div
                    className="text-center-css"
                    style={{
                      textAlign: "center",
                      padding: "60px 30px 0px 30px",
                      wordBreak: "break-word",
                    }}
                  >
                    Please wait while we are processing your payment
                  </div>
                </div>
              ) : (
                <div>
                  {paymentStatus === "success" && (
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        paddingTop: "55px",
                        paddingBottom: "30px",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                      }}
                    >
                      <Lottie
                        loop
                        animationData={success}
                        play
                        style={{ width: 150, height: 150 }}
                      />
                      <div className="failure-status">Payment successfull</div>
                      <div className="currency-value">
                        {"$" + formatAmountWithCommas(statusDetail?.amount)}
                      </div>
                      <div className="text-center-css">
                        Your payment has been successfully
                        <br /> processed
                      </div>
                      <div className="transaction-tag  ">
                        <span>Transaction id</span>
                        <span>{statusDetail.transaction_id}</span>
                      </div>
                    </div>
                  )}
                  {paymentStatus === "processing" && (
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        paddingTop: "55px",
                        paddingBottom: "30px",
                        paddingLeft: "30px",
                        paddingRight: "30px",
                      }}
                    >
                      <Lottie
                        loop
                        animationData={processing}
                        play
                        style={{ width: 150, height: 150 }}
                      />
                      <div className="failure-status">Payment Processing</div>
                      <div className="text-center-css">
                        Your payment being processed.Will
                        <br /> update your payment automatically
                      </div>
                    </div>
                  )}
                  {paymentStatus === "failure" && (
                    <div
                      className=""
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        paddingTop: "55px",
                        paddingBottom: "30px",
                      }}
                    >
                      <img src={error} style={{ paddingBottom: "20px" }} />
                      <div className="failure-status">Payment failed</div>
                      <div className="text-center-css">
                        {" "}
                        Your payment was not successfully processed
                        <br />
                        Please try again after some time
                      </div>
                    </div>
                  )}
                </div>
              )}
            </Modal>

            <Tab id={id} secondId={secondId} payments={payments} />
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
            paddingTop: "20%",
          }}
        >
          <Spin
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 50,
                  color: "#0b8080",
                }}
                spin
              />
            }
          />
        </div>
      )}
    </div>
  );
}
export default Index;

import React, { Component } from "react";
import overall from "../assets/Company/overall.svg";
import paid from "../assets/Company/paid.svg";
import pending from "../assets/Company/pending.svg";
import { capitalize, capitalizeFirstLetter, fixedAmount, formatAmountWithCommas } from "../constants/utils";
import { Modal, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import Timelineimg from "../assets/Company/invoiceTimeline.svg";
import { showMomentFormat } from "../constants/utils";
import "../styles/tableStyle.scss"
import Apiservice from "../constants/Apiservice";
import PayMethod from "../components/payMethod";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import Timeline from "../components/Timeline/Timeline";
import { UncontrolledDropdown } from "reactstrap";
import Env from "../constants/Env";
import showmore from "../assets/Company/icons/showMore.svg"
import Download from "../assets/Company/download.svg";



const paymentInfo = [
  { title: "Overall", value: "600", img: overall },
  { title: "Paid", value: "600", img: paid },
  { title: "Pending", value: "600", img: pending },
];

const color = {
  processing: "#ff8e3c",
  pending: "#FF8E3C",
  paid: "#008000",
  cancelled: "#F25138",
  created: "#FF8E3C"
};











export default class Payments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // paymentInfo: paymentInfo,
      paymentInfo: [],
      // project:this.props.project,
      payments: [],
      invoiceList: [],
      newArray: [],
      isModalopen: false,
      value: 1,
      invoiceId: "",
      invoiceAmount: "",
      openTimeLine: false,
      TimeLineData: {},
      invoicelazyLoader: false,
      itemStates: (this?.state?.invoiceList || [])?.map(() => false)
    };
  }




  openModal = (id, amount) => {

    this.setState({ invoiceId: id, isModalopen: true, invoiceAmount: amount })
  }

  closeModal = () => {
    this.setState({ isModalopen: false })
    
  }

  radioValue = (e) => {
    this.setState({ value: e.target.value })
  }

  componentDidMount = () => {
    const { id_value } = this?.props;
    const projectId = id_value?.pro_id;
    if (projectId) {
      this.list_api()
    }

  }


  componentDidUpdate(prevProps, prevState) {
    const { id_value } = this.props;
    const projectId = id_value?.pro_id;
    const { newArray } = this.state

    // Check if projectId has changed
    if (projectId !== prevProps.id_value?.pro_id) {
      this.list_api();
    }
  }






  list_api = async () => {
    const id = this.props.id_value;
    const response = await Apiservice.getAPI(`auth/contractor/invoice/list/${id.client_id}/${id.pro_id}`);
    try {
      if (response.success) {
        this.setState({ invoiceList: response.data.invoices }, () => {
          // Perform mapping operation inside setState callback
          const newArray = this.state.invoiceList.map((item) => ({
            id: item?.invoice_id ?? "",
            name: capitalizeFirstLetter(item?.title) ?? "",
            created_on: showMomentFormat(item?.createdAt) ?? "",
            due_on: showMomentFormat(item?.due) ?? "",
            amount: `${item?.amount ?? ""}`,
            status: item?.status ?? "",
            loader: false,

          }));

          // Update newArray in state
          this.setState({ newArray });
        });
      }
    } catch (err) {
    }
  };


  openFunc = (value) => {
    this.setState({ openTimeLine: true })
    const timelineData = this.state.invoiceList.find(options => options.invoice_id === value)
    this.setState({ TimeLineData: timelineData })
  }



  toggleItem = (index) => {
    this.setState(prevState => {
      const newStates = Array.isArray(prevState.itemStates) ? [...prevState.itemStates] : (this?.state?.invoiceList || [])?.map(() => false);
      newStates[index] = !newStates[index];
      return { itemStates: newStates };
    });
  };

  download = async (invoiceid, index) => {

    this.toggleItem(index)
    const id = this.props.id_value;
    this.setState({ btnLoading: true })
    fetch(Env?.BASE_URL + `auth/contractor/invoice/download/${id?.client_id}/${invoiceid}`)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${invoiceid}`
        document?.body?.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        this.setState({ itemStates: (this?.state?.invoiceList || [])?.map(() => false) })

      })
      .catch(error => {
        this.setState({ itemStates: (this?.state?.invoiceList || [])?.map(() => false) })

      });
  }

  render() {
    const { isModalopen, invoiceAmount, openTimeLine, invoicelazyLoader } = this?.state;


    const columns = [
      {
        title: <span className="custom-th">ID</span>,
        dataIndex: "id",
        className: "width15 custom_td",
      },
      {
        title: <span className="custom-th">Title</span>,
        dataIndex: "name",
        className: "width15 custom_td",
      },
      {
        title: <span className="custom-th">Created on</span>,
        dataIndex: "created_on",
        className: "width15 custom_td",
      },
      {
        title: <span className="custom-th">Due on</span>,
        dataIndex: "due_on",
        className: "width15 custom_td",
      },
      {
        title: <span className="custom-th">Amount</span>,
        dataIndex: "amount",
        className: "width15 custom_td",
        render: (_, record) => (
          <div>
            <div>{"$" + fixedAmount(record?.amount)}</div>
          </div>
        ),
      },
      {
        title: <span className="custom-th">Status</span>,
        dataIndex: "status",
        className: "width10 custom_td",
        render: (status, record) => (
          <div
            style={{ color: color[status], fontWeight: "600", fontSize: "16px" }}
          >
            {capitalize(status)}
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "pay",
        className: "width8 custom_td",
        render: (_, record) => (

          <div className={`${record.status === 'created' ? "end" : "pay-paid"}`} >
            <button className="btn_primary" onClick={() => this.openModal(record.id, record.amount)}>Pay Now</button>
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "tags",
        className: "width8 custom_td ",
        render: (_, record) => (
          <div className="end">
            <img
              className="pointer"
              width={18}
              height={18}
              src={Timelineimg}
              alt="timeline"
              onClick={() => this.openFunc(record?.id)}
            ></img>
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "",
        className: "Dropdown",
        render: (_, record, index) => (
          <div className="dot-align">
            {
              this?.state?.itemStates[index] ? <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 20,
                      fontWeight: 700,
                      color: "#002A4D",
                      alignSelf: "center",
                    }}
                    spin
                  />
                }
              /> : <img
                onClick={() => this?.download(record?.id, index)}
                style={{ width: "20px", height: "18px" }} src={Download} />
            }



          </div>
        ),
      },
    ];







    return (
      <>
        {this.props.payments &&
          this.props.payments?.map((item) => {
            return (
              <div className="py_card">
                <div className="paymentCard">
                  <div className="card">
                    <div className="img">
                      <img src={overall} />
                    </div>
                    <div style={{ width: "100px" }}>
                      <div className="value W_700">${formatAmountWithCommas(fixedAmount(item?.completedPayments + item.pendingPayments))}</div>
                      <div className="title fs_15">Overall</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="img">
                      <img src={paid} />
                    </div>
                    <div style={{ width: "100px" }}>
                      <div className="value W_700">${formatAmountWithCommas(fixedAmount(item?.completedPayments))}</div>
                      <div className="title fs_15">Paid</div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="img">
                      <img src={pending} />
                    </div>
                    <div style={{ width: "100px" }}>
                      <div className="value W_700">${formatAmountWithCommas(fixedAmount(item?.pendingPayments))}</div>
                      <div className="title fs_15">Pending</div>
                    </div>
                  </div>

                </div>
              </div>
            )
          })
        }

        <div className="customTable">
          {
            this.state.invoiceList?.length > 0 ?
              <Table

                columns={columns} dataSource={this?.state?.newArray && this.state.newArray} /> : <Table columns={columns} />
          }
          <Modal closable={false} width={650} title="" open={isModalopen} footer={null}>

            <PayMethod
              props={this.props}
              invoice_id={this?.state?.invoiceId}
              close={this.closeModal}
              amount={invoiceAmount}
              ismodal={this.state.isModalopen}
            />
          </Modal>

          {
            openTimeLine && <Timeline timelineData={this?.state?.TimeLineData} open={this?.state?.openTimeLine} onclose={() => this.setState({ openTimeLine: false })} />
          }
        </div>
      </>
    );
  }
}

import React, { Component, useEffect, useState } from "react";
// import { useNavigate } from 'react-router';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import "../style.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import ClientNavBar from "../components/navbar/ClientNavBar";
import Index from "../views";
import Profile from "../views/profile";
import Notfound from "../views/Notfound";
function ClientPageLayout() {
  const [projects, setProjects] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.pathname === "/") {
      navigate("client", { replace: true });
    }
  }, []);

  const getprojects = function (projects) {
    // setProjects(projects);
  };

  const loading = (
    <div className="spin-loading">
      <Spin
        indicator={
          <LoadingOutlined
            style={{
              fontSize: 24,
            }}
            spin
          />
        }
      />
    </div>
  );

  return (
    <>
      <div>
        {/* <ClientNavBar projectsLists={getprojects} /> */}
        <Routes>
          <Route path="/client/:id/:secondId?" element={<Index projects={projects} />} />
          <Route path="/client/profile" element={<Profile />} />
          <Route path="/notfound" element={<Notfound />} />

          <Route path="*" element={<Navigate to="/notfound" />} />
        </Routes>
      </div>
    </>
  );
}

export default ClientPageLayout;

import React, { Component } from "react";
import { Table } from "antd";
import Timeline from "../assets/Company/invoiceTimeline.svg";
import { capitalize, fixedAmount, formatAmountWithCommas } from "../constants/utils";
import { showMomentFormat } from "../constants/utils"
import Apiservice from "../constants/Apiservice";
import EstimateAccepRejecttModal from "../components/AcceptRejectModal/estimateAcceptModalReject";
import ToastList from "../components/Toast/ToastList";
const color = {
  pending: "#FF8E3C",
  paid: "#008000",
  accepted: "#008000",
  cancelled: "#F25138",
  rejected: "#F25138",
  created: "#FF8E3C"

};




export default class ChangeOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeOrder: [],
      TableData: [],
      openModal: false,
      type: "",
      changeOrderId: "",
      toast:false,
      response:"",
      toastType:""

    };
  }


  componentDidMount = () => {
    this.list_api()
  }


  componentDidUpdate(prevProps, prevState) {
    const { id_value } = this.props;
    const projectId = id_value?.pro_id;
    // Check if projectId has changed
    if (projectId !== prevProps.id_value?.pro_id) {
      this.list_api();
    }
  }

  list_api = async () => {
    const id = this.props.id_value;
    const response = await Apiservice.getAPI(`auth/contractor/changeOrder/${id?.client_id}/${id?.pro_id}`);
    try {
      if (response.success) {
        this.setState({ changeOrder: response.data }, () => {
          // Perform mapping operation inside setState callback
          const TableData = this?.state?.changeOrder?.map((item) => ({
            id: item?.changeOrder_id,
            title: item?.title ?? "",
            created_on: showMomentFormat(item?.createdAt) ?? "",
            due_on: showMomentFormat(item?.due_date) ?? "",
            cost: "$"+fixedAmount(item?.amount) ?? "",
            status: item?.status ?? ""
          }));

          this.setState({ TableData });
        });
      }
    } catch (err) {
    }
  };


  acceptRejectProposal = (value, data, orderid) => {
    this.setState({ openModal: true, type: value, status: 'the change order', changeOrderId: orderid });
  };

  toggleModal = () => {
    this.setState({ openModal: false });
    this.list_api();
  };


  AcceptRejectWorkorder = async (status, reason) => {
    let stats
    if (status === 'accept') stats = 'accepted'
    else stats = 'rejected'
    try {

      let data = {};

      if (stats === 'rejected') {
        data = { reason: reason };
      }


      const response = await Apiservice.patchAPI(`auth/contractor/changeOrder/${this?.state?.changeOrderId}/${stats} `, data);


      if (response.success) {
        this.setState({
          openModal: false,
          

        });
        this.list_api()

      }

      else {
        this.setState({
          openModal: false,
          // isStatusPopup: true,
          // popupStatus: stats

        });
        this.list_api()

      }
    } catch (error) {
      console.error('Error fetching estimate details:', error);
    }
  }



  render() {
    const columns = [
      {
        title: <span className="custom-th">Title</span>,
        dataIndex: "title",
        className: "width15 custom_td",
      },
      {
        title: <span className="custom-th">Created on</span>,
        dataIndex: "created_on",
        className: "width15 custom_td",
      },
      {
        title: <span className="custom-th">Due on</span>,
        dataIndex: "due_on",
        className: "width15 custom_td",
      },
      {
        title: <span className="custom-th">Cost</span>,
        dataIndex: "cost",
        className: "width15 custom_td",
      },
      {
        title: <span className="custom-th">Status</span>,
        dataIndex: "status",
        className: "width10 custom_td",
        render: (status, record) => (
          <div
            style={{ color: color[status], fontWeight: "600", fontSize: "16px" }}
          >
            {capitalize(status)}
          </div>
        ),
      },
      {
        title: "",
        dataIndex: "tags",
        className: "width15 custom_td",
        render: (_, record) => (
          <div >
            {
              record?.status === 'created' &&
              <div style={{width:"100%",display:"flex",gap:"10%"}}>
                <button onClick={() => this.acceptRejectProposal("reject", "", record?.id)} className="btn_sec">Reject</button>
                <button onClick={() => this.acceptRejectProposal("accept", "", record?.id)} className="btn_primary">Accept</button>
              </div>

            }


          </div>
        ),
      },
    ]


    const { TableData, type,toast,response,toastType } = this.state

    return (
      <>
      {toast && <ToastList message={response} type={toastType} />}
        <div className="mt_20 customTable changeOrder">
        

          {
            this.state.TableData?.length > 0 ? <Table
            className="custom-table"
              columns={columns} dataSource={TableData && TableData} /> : <Table columns={columns} />
          }
          <div style={{ width: "100%", height: "100%" }}>
            {this.state.openModal && (
              <EstimateAccepRejecttModal
                props={this}
                toggle={() => this.toggleModal()}
                isOpen={this.state.openModal}
                subject={type}
                estimate={this.state.status}
                action={(reason) => { this.AcceptRejectWorkorder(type, reason) }}
              // sendToParent={this.setState(reason)}
              ></EstimateAccepRejecttModal>
            )}
          </div>

        </div>
      </>
    );
  }
}

import React, { useState, useEffect, useRef, useCallback } from "react";
import { Input, message } from "antd";
import send from "../assets/Company/send.svg";
import Apiservice from "../constants/Apiservice";
import moment from "moment";
import { Modal, Spin, Table } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { dateformat } from "../constants/utils";
import NoData from "../assets/Company/Lotties/noData.json"
import Lottie from "react-lottie-player";
const { TextArea } = Input;

const Chats = (props) => {
  const [textAreaHeight, setTextAreaHeight] = useState("28px");
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(30);
  const [height, setHeight] = useState(0)
  const [total, setTotal] = useState("")
  const textareaRef = useRef(null);
  const chatWindowRef = useRef(null);
  const [isloading, setIsLoading] = useState(false)
  const [viewmsg, setViewMsg] = useState("")
  const [chatDate, setChatDate] = useState({})
  const [chatCurrentcount, setcchatcurrentCount] = useState("")

  const newChatDateRef = useRef({});


  const lastChatRef = useCallback(node => {
    if (node !== null) {
      node.scrollIntoView();
    }

  }, []);

  useEffect(() => {
    chatGet("pagechange");
    newChatDateRef.current = {}
  }, [page, props?.id_value.pro_id]);


  const update = (e) => {
    setMessage(e?.target?.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !e.ctrlKey) {
      e.preventDefault();
    } else if (e.key === "Enter" && (e.shiftKey || e.ctrlKey) && !e.repeat) {
      e.preventDefault();
    }
    if (e.key === "Enter" && !e.repeat && !e.shiftKey && !e.ctrlKey) {
      sendMessage();
    }
  };


  const sendMessage = async () => {
    const id_value = props.id_value;
    let data = {
      message: message
    };

    if (data.message) {
      const response = await Apiservice.postAPI(
        `auth/contractor/project/chat/${id_value?.client_id}/${id_value.pro_id}`,
        data
      );
      if (response.success) {
        setPage(1)
        setMessage("");
        chatGet();
      }
    }
  };

  const chatGet = async (change) => {


    change ? setIsLoading(true) : setIsLoading(false)
    const id_value = props?.id_value;
    const response = await Apiservice?.getAPI(
      `auth/contractor/project/chat/${id_value?.client_id}/${id_value.pro_id}?page=${page}&limit=${limit}`
    );

    try {
      if (response?.success) {
        const prevScrollHeight = chatWindowRef.current.scrollHeight;
        if (page > 1) {
          // setIsLoading(true)
          setChats(prevChats => [...prevChats, ...response?.data?.chats]);
          setcchatcurrentCount(response?.data?.chats.length)
          setTotal(response?.data?.count);
          setIsLoading(false);

        } else {
          // setIsLoading(true)
          setChats([...response?.data?.chats]);
          setcchatcurrentCount(response?.data?.chats.length)
          setTotal(response?.data?.count);
          setIsLoading(false);
        }


      }
    } catch (err) {
    }
  };









  const handleScroll = (e) => {
    const element = e.target
    const scrollHeight = element.scrollHeight
    const currentHeight = element.scrollTop
    const customheight = scrollHeight - element.clientHeight
    const totalPages = Math.ceil(total / limit);

    if (page < totalPages &&
      !isloading &&
      element.scrollTop === 0
      // Math.abs(element.scrollTop) + element.clientHeight + 10 >= element.scrollHeight


    ) {
      setTimeout(() => {
        setPage(page + 1)
      }, 1000);
    }
  }






  return (
    <div className="mt_20">
      <div className={chats.length > 0 ? "chatContainer" : "chatContainer-nodata"}>
        <div id="messageDiv" className="chat_body" ref={chatWindowRef}
          onScroll={handleScroll}
          style={{

          }}
        >
          {chats?.length > 0 ?
            !isloading ?
              chats &&
              chats?.slice(0).reverse().map((obj, index) => {
                // const isLastMessage = page > 1 ? index + 1 === (page * limit) - limit : null
                const isLastMessage = index + 1 === chatCurrentcount

                let today = "";

                if (!newChatDateRef?.current[dateformat(obj?.createdAt).toString()]) {
                  newChatDateRef.current[dateformat(obj?.createdAt).toString()] = dateformat(obj?.createdAt);
                  today = dateformat(obj.createdAt);
                } else {
                  today = "";
                }




                return (
                  <div>
                    <div style={{ display: 'flex', justifyContent: "center", width: "100%" }}>
                      {
                        today && <div style={{ display: "flex", alignItems: "center", width: "100%" }}>

                          <hr style={{ width: "38%", height: "0.1px", background: "#757575" }} />

                          <p style={{ padding: "10px 20px", background: "#fff", width: "20%", textAlign: "center", borderRadius: "6px", font: "16px", fontWeight: "600", fontFamily: "sans-serif", color: "#757575" }}>
                            {
                              today === dateformat(new Date().toISOString()) ? "Today" : today}</p>
                          <hr style={{ width: "38%", height: "0.1px", background: "#757575" }} />


                        </div>
                      }


                    </div>
                    <div
                      key={index}
                      ref={isLastMessage ? lastChatRef : null}
                      tabIndex={index + 1}
                      className={
                        obj.sender.id === props.id_value.client_id
                          ? "right_container"
                          : "left_container"
                      }
                    >
                      <div
                        className={
                          obj.sender.id === props.id_value.client_id
                            ? "right_card"
                            : "left_card"
                        }
                      >
                        {obj.sender.id !== props.id_value.client_id && (
                          <div style={{ fontSize: "12px" }}>{obj.sender.name}</div>
                        )}
                        <div>{obj.message}</div>
                        <div style={{ fontSize: "12px", paddingTop: "6px" }}>
                          {moment(obj.createdAt).format("h:mm a")}
                        </div>
                      </div>
                    </div>
                  </div>

                )


              })
              : <div style={{ width: "100%", display: "flex", justifyContent: 'center', alignItems: "center", height: "100%" }}>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 50,
                        fontWeight: 900,
                        color: "#0b8080",
                        alignSelf: "center",
                      }}
                      spin
                    />
                  }
                />
              </div> :
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "450px", flexDirection: "column" }}>
              <Lottie
                loop
                animationData={NoData}
                play
                style={{ width: 150, height: 150 }}
              />
              <p style={{ font: "17px", fontWeight: "600", fontFamily: "sans-serif", color: "#757575" }}>No chats found</p>
            </div>
          }
        </div>
      </div>
      <div className="chatContainer_footer">
        <textarea
          className="customTextarea"
          ref={textareaRef}
          style={{ height: textAreaHeight }}
          onChange={update}
          value={message}
          onKeyDown={handleKeyDown}
          placeholder="Type here..."
        />
        <div>
          <img
            className="pointer"
            onClick={sendMessage}
            src={send}
            alt="send button"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Chats;

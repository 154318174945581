import React, { Component } from "react";
import ClientPageLayout from "../layouts/ClientPageLayout";
// import { withRouter } from 'react-router-dom';

 class PrivateRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return <ClientPageLayout />;
  }
}
export default PrivateRouter

import { Drawer, Flex } from 'antd'
import React from 'react'
import closeimg from "../../assets/Company/icons/white_close_icon.svg"
import { showDatenTime } from '../../constants/utils'

export default function Timeline(props) {
  const { open, onclose, timelineData } = props
  const list = timelineData?.timeline
  return (
    <div>
      <Drawer width={448} open={open} closable={false} onClose={() => onclose()} className='drawer' >
        <div className='drawer-header'>
          <p className='drawer-title'>Timeline</p>
          <img className='close-icon' src={closeimg} onClick={() => onclose()} />
        </div>
        <div style={{ width: "100%" }}>
          {
            list.map((item, index) => {
              return (
                <div>
                  <div style={{ display: "flex", justifyContent: "start", alignItems: "start", height: "79px" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', height: "100%", paddingRight: "20px", paddingTop: '25px', paddingLeft: "20px" }}>

                      <div className='timeLineRound'></div>
                      {index < list?.length - 1 && (
                        <div className="timeLineVerLine"></div>
                      )}
                    </div>
                    <div style={{ paddingTop: "21px" }}>
                      <div className='timeline-msg'>{item?.msg}</div>
                      <div className='timeline-time'>  {showDatenTime(item.time)}</div>
                    </div>
                  </div>
                </div>
              )
            })
          }
          {
            list?.map((item) => {
              return (
                <div style={{ width: "auto" }} >
                  {
                    item?.reason && <div className='timeline-reason' >Reason : <span className='reason-value'>{item?.reason}</span></div>
                  }
                </div>
              )
            })
          }
        </div>

      </Drawer>


    </div>

  )
}

import React, { Component } from "react";
import { isLoggedIn } from "../constants/utils";
import { BrowserRouter as Router } from "react-router-dom";
import PrivateRouter from "./PrivateRouter";
import PublicRouter from "./PublicRouter";
export default class MainRouter extends Component {
  render() {
    return <><PrivateRouter /></>;
  }
}

// {isLoggedIn() ? <PrivateRouter /> : <PublicRouter />}